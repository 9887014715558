import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import HeaderLogo from '../header-logo';
import HamburguerButton from './components/hamburguer-button';
import useBodyClass from '../../../../hooks/useBodyClass';
import { useFixedPositions } from '../../../../providers/fixed-position-provider/fixed-position-provider';
import SignIn from '../sign-in';

// eslint-disable-next-line max-lines-per-function
const HeaderMobile = ({ isLoggedIn, navProps, className, theme, showMenuLinks, partnerLogo, secondaryLinks }) => {
  const subMenuHeapTrack = async(analytics_key) => {};
  const [showMenu, setShowMenu] = useState(false);
  const hasSecondaryBar = ( secondaryLinks.length > 0 );
  const [secondaryNavBar, setSecondaryNavBar] = useState(false);
  const [sidebar, setSidebar] = useState(false);
  const [showBanner, setShowBanner] = useState(false);
  const [deviceType, setDeviceType] = useState('web');
  const {
    subscribeToFixedPosition,
    unsubscribeToFixedPosition
  } = useFixedPositions();

  useBodyClass(showMenu ? 'is-open-mobile-menu' : 'is-closed-mobile-menu');

  useEffect(() => {
    const subscription = subscribeToFixedPosition();
    subscription.setOffsetTop(50);
    return () => {
      unsubscribeToFixedPosition(subscription);
    };
  }, [subscribeToFixedPosition, unsubscribeToFixedPosition]);

  const hamburguerHandler = isOpen => {
    if (hasSecondaryBar) {
      setSecondaryNavBar(!isOpen);
    } else {
      setShowMenu(!isOpen);
    }
  };

  const showSidebar = () => {
    setSidebar(!sidebar);
  };
  useEffect(() => {
      let userAgent = navigator.userAgent || navigator.vendor || window.opera;

      if (/android/i.test(userAgent)) {
          // Android Case
          setDeviceType('android');
      } else if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
          // iOS Case
          setDeviceType('ios');
      } else {
          // Fallback URL
          setDeviceType('web');
      }
  }, []);
  const tabsLength = secondaryLinks.length;
  const tabsDifferentThanFive = tabsLength !== 5;

  const classNamesItemNarrowContent = (active) => classNames({
    'leaf-content': tabsDifferentThanFive,
    'leaf-narrow': !tabsDifferentThanFive,
    'leaf-content--active': tabsDifferentThanFive && active,
    'leaf-narrow--active': !tabsDifferentThanFive && active
  });

  const renderMenuItems = () => {
    return showMenuLinks ? (
      <div
        className={classNames('GlobalHeaderMobile__slidingMenu', {
          'GlobalHeaderMobile__slidingMenu--show': showMenu || secondaryNavBar
        })}
      >
        <div className={classNames('GlobalHeaderMobile__Navs GlobalHeaderMobile__subnav', {
          'GlobalHeaderMobile__subnav--show': secondaryNavBar, 'GlobalHeaderMobile__subnav--hide': sidebar})}>
          { renderSecondaryTabs() }
        </div>
      </div>
    ) : null;
  };

  useEffect(() => {
    const bannerFlag = localStorage.getItem('hideBanner');
    if (bannerFlag) {
      setShowBanner(false);
    } else {
      if (window.lcTracking) {
        window.lcTracking.trackCustomEvent({
          eventName: 'auth_app_banner_view'
        });
      }
      setShowBanner(true);
    }
  }, []);

  const hideBanner = () => {
    setShowBanner(false);
    localStorage.setItem('hideBanner', true);
    if (window.lcTracking) {
      window.lcTracking.trackCustomEvent({
        eventName: 'auth_app_banner_exit'
      });
    }
  };
  const renderSecondaryTabs = () => {
    if ( !hasSecondaryBar ) {
      return null;
    }
    return (
      <ul className='nav-list'>
        <li className="nav-back" onClick={showSidebar}>Main Menu</li>
        {secondaryLinks.map((item, index) => {
        const analytics_key = item.text.toLowerCase().split(' ').join('_');
          return (
            <li key={index} className="leaf">
              <a
                id={`list-item-a-${index}`}
                href={item.url}
                className={classNamesItemNarrowContent(item.active)}
                onClick={() => subMenuHeapTrack(analytics_key)}
              >
              {item.text}
              </a>
            </li>
          );
        })}
      </ul>
    );
  };

  return (
    <header
      className={classNames('GlobalHeaderMobile', className, {
        [`GlobalHeaderMobile--${theme}`]: !!theme,
        'GlobalHeaderMobile--open': !!showMenu
      })}
    >
      {showBanner && (
        <div className="auth-banner-mobile">
          <div className="auth-banner__close" onClick={hideBanner}></div>
          <div className="auth-banner__info">
            <div className="auth-banner__title">Banking is Better on the App!<sup>1</sup></div>
            <div className="auth-banner__subtitle">LendingClub App only available to members with personal banking and loan products.</div>
          </div>
          <div className="auth-banner__img">
            { (deviceType === 'ios' || deviceType === 'web') && (
              <a className="auth-banner__apple-store" target="_blank" href="https://itunes.apple.com/us/app/ft-mobile/id532638447?mt=8" rel="noreferrer"/>
            )}
            { (deviceType === 'android' || deviceType === 'web') && (
            <a className="auth-banner__google-play" target="_blank" href="https://play.google.com/store/apps/details?id=com.q2e.firsttradeunionbank3351.mobile.production" rel="noreferrer"/>
            )}
          </div>
        </div>
      )}
      <div className="GlobalHeaderMobile__wrapper" style={{position: `${showBanner ? 'relative' : 'fixed'}`}}>
        <HeaderLogo theme="blue" />
        <SignIn isLoggedIn={ isLoggedIn } />
        {showMenuLinks ? (
          <HamburguerButton toggleCallback={hamburguerHandler} />
        ) : null}
        {partnerLogo && <img alt="Partner Logo" src={ partnerLogo } className="GlobalHeaderMobile__logo--partner" />}
      </div>
      {renderMenuItems()}
    </header>
  );
};

HeaderMobile.defaultProps = {
  theme: 'default',
  showMenuLinks: true
};

HeaderMobile.propTypes = {
  className: PropTypes.string,
  theme: PropTypes.string,
  isLoggedIn: PropTypes.bool,
  navProps: PropTypes.object,
  showMenuLinks: PropTypes.bool,
  partnerLogo: PropTypes.string,
  secondaryLinks: PropTypes.array
};

export default React.memo(HeaderMobile);

require('/var/lib/jenkins/workspace/auth-ui_Release_0/src/shared_modules/components/global-header/components/header-mobile/header-mobile.scss');